export const routes = [{
        path: '/main',
        name: 'Main',
        component: r => require(['../views/Main.vue'], r)
    },
    {
        path: '/login',
        name: 'Login',
        component: r => require(['../views/Login.vue'], r)
    },
    {
        path: '/adminHome',
        name: 'AdminHome',
        component: r => require(['../views/AdminHome.vue'], r),
        children: [
            {
                path: 'orderInfo',
                name: 'OrderInfo',
                component: r => require(['../views/mainViews/OrderInfo.vue'], r)
            },
            {
                path: 'orderQuery',
                name: 'OrderQuery',
                component: r => require(['../views/mainViews/OrderQuery.vue'], r)
            },
            {
                path: 'updatePsw',
                name: 'UpdatePsw',
                component: r => require(['../views/mainViews/UpdatePsw.vue'], r)
            },
            {
                path: 'trainVideo',
                name: 'TrainVideo',
                component: r => require(['../views/mainViews/TrainVideo.vue'], r)
            },
        ]
    },
    {
        path: '/editOrder',
        name: 'EditOrder',
        component: r => require(['../views/EditOrder.vue'], r)
    },
    {
        path: '/customer',
        name: 'Customer',
        component: r => require(['../views/Customer.vue'], r)
    },
    {
        path: '/reportForms',
        name: 'ReportForms',
        component: r => require(['../views/ReportForms.vue'], r)
    },
    {
        path: '/companyLogin',
        name: 'CompanyLogin',
        component: r => require(['../views/CompanyLogin.vue'], r)
    },
    {
        path: '*',
        redirect: {
            name: 'Main'
        }
    }
    // {
    //     path: '/orderInfo',
    //     name: 'OrderInfo',
    //     component: r => require(['../views/OrderInfo.vue'], r)
    // },
    // {
    //     path: '/orderQuery',
    //     name: 'OrderQuery',
    //     component: r => require(['../views/OrderQuery.vue'], r)
    // },
    // {
    //     path: '/updatePsw',
    //     name: 'UpdatePsw',
    //     component: r => require(['../views/UpdatePsw.vue'], r)
    // },
]