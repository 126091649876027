import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
import $ from 'jquery'

import 'bootstrap'
import 'jquery/dist/jquery.min'
import 'popper.js/dist/popper.min'
import vuetify from '@/plugins/vuetify'

import 'font-awesome/css/font-awesome.min.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'

import {DatePicker, Carousel, CarouselItem, Backtop, Icon, Tooltip, Button, Popover, Row, Col, Menu, Submenu, MenuItemGroup, MenuItem, Message} from 'element-ui'

import moment from 'moment'

import BaiduMap from 'vue-baidu-map'

import Video from 'video.js'
import 'video.js/dist/video-js.css'

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
//引入 hls,视频直播(m3u8)必须引入的
import 'videojs-contrib-hls'
//播放rtmp视频
import 'videojs-flash'
Vue.use(VideoPlayer);

Vue.use(BaiduMap, {
  ak: 'OwXABIYnYI6RbAClid5gZIGduYdEFg3v'
});

// 注册axios
Vue.use(VueAxios, axios)

Vue.use(DatePicker);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Backtop);
Vue.use(Icon);
Vue.use(Tooltip);
Vue.use(Button);
Vue.use(Popover);
Vue.use(Row);
Vue.use(Col);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItemGroup);
Vue.use(MenuItem);


// 配置post请求头
axios.defaults.headers.post['Content-Type'] = 'text/plain';

Vue.prototype.$moment = moment;

Vue.prototype.$axios = axios;

Vue.prototype.$video = Video;

Vue.prototype.$message = Message;

Vue.config.productionTip = false;

// 设置项目页面标题
Vue.directive('title', {
  inserted: function(el, binding){
    document.title = el.dataset.title
  }
})

//全局过滤器
Vue.filter('formatDate', (value, format) => {
  //value: 2020-07-08T02:40:51.000Z
  //format: yyyy-MM-dd hh:mm:ss
  let current = new Date(value);

  //获取年份
  let year= current.getFullYear();
  // 
  if (/(y+)/.test(format)) {
    //获取匹配组的内容
    let yearContent = RegExp.$1;
    // 

    format = format.replace(yearContent, year);
  }

  //处理月日时分秒
  let dateObject = {
    M: current.getMonth() + 1,
    d: current.getDate(),
    H: current.getHours(),
    m: current.getMinutes()
  };

  for (let key in dateObject) {
    //创建动态正则表达式
    let reg = new RegExp(`(${key}+)`)
    // 
    if (reg.test(format)) {
      //获取匹配组的内容, RegExp.$n必须需要test验证通过可以获取
      let content = RegExp.$1;
      // 

      //控制补零
      let value = dateObject[key] >= 10 ? dateObject[key] : content.length == 2 ? '0' + dateObject[key] : dateObject[key];
      format = format.replace(content, value);
    }
  }

  return format;
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
