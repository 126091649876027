<template>
  <div id="app" @click="isTimeOut">

    <!-- 一级路由 -->
    <router-view></router-view>
    
  </div>
</template>

<script>
// 公共组件
import NavMenu from './components/NavMenu.vue'

export default {

  // 公共组件
  components:{
    NavMenu:NavMenu
  },

  name: 'App',
  data(){
    return{
      // 最后点击时间
      lastTime: null,
      // 当前点击时间
      currentTime: null,
      // 超时时间
      timeOut: 60*60*1000
    }
  },
  created(){
    this.lastTime = new Date().getTime();
  },
  methods:{
    isTimeOut(){
      this.currentTime = new Date().getTime();
      if(this.currentTime - this.lastTime > this.timeOut){
        if(sessionStorage.getItem('user')){
          alert('登录超时，请重新登录');
          this.$router.push({name: 'Login'});
          sessionStorage.clear();
        }else{
          this.lastTime = new Date().getTime();
        }
      }else{
        this.lastTime = new Date().getTime();
      }
    }
  }
  
}
</script>

<style lang="less">
// 全局样式
html,body,#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.auto-img {
  width: 100%;
  display: block;
}
</style>
